<template>
    <div class="master-toko">
        <header-page>
            <span class="size14 fw-bold-700" style="color: #0B5FFF;margin-right: 5px;">Master</span>
            <span class="text-dark text-darken-5" style="margin-right: 5px;">></span>
            <span class="text-dark fw-bold-400 size14">Kategori</span>
        </header-page>

        <TableBarang :result="result" :delete-data="deleteData" :get-data="getData" :edit-item="editItem"
            @filter="getFilter" />

        <ModalBarang :form-data="formPayload" :create-item="createItem" :edit-id="editId" :edit-item="editItem"
            :update-item="updateItem" :clean-up-form="cleanUpForm" :icon-bank="iconBank" @getPayload="getPayload" />
        <b-modal id="import-modal" no-close-on-backdrop size="lg" title="Import" hide-footer>
            <div class="form">
                <div class="form-group">
                    <label for="">File:</label><br>
                    <input id="fileImport" ref="fileImport" type="file" @change="changeImportFile()">
                </div>

                <div v-if="isLoadingImport">
                    <br>
                    <b-spinner class="mb-2" variant="primary" /><br>
                </div>
                <button v-else class="btn btn-primary btn-sm" @click="importFile()">
                    Import Data
                </button>
                <br>
                <hr>
                <small>Download import format <a href="/import-format/sample_import_category-new.xlsx">here</a></small>

            </div>
        </b-modal>
    </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
    VBToggle, BSpinner
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import HeaderPage from '@/components/HeaderPage.vue'
import TableBarang from '@/components/Master/Category/Category1/TableBarang.vue'
import ModalBarang from '@/components/Master/Category/Category1/Modal/ModalBarang.vue'

export default {
    title() {
        return 'Kategori 1'
    },
    components: {
        HeaderPage,
        TableBarang,
        ModalBarang,
        BSpinner
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data() {
        return {
            currentPage: 1,
            isLoading: true,
            result: {},
            validations: [],
            editId: null,
            iconBank: null,
            formPayload: {
                name: '',
                description: '',
                logo: '',
                item_uuid: null
            },
            filter: {
                search: '',
                per_page: 10,
                sort_type: 'asc',
            },
            isLoadingImport: false,
            importPayload: {
                file: null,
            },
        }
    },
    setup() {
        return {
            checkPermission,
            successNotification,
            errorNotification,
        }
    },
    watch: {
        filter: {
            handler: _.debounce(function () {
                this.getData()
            }, 300),
            deep: true,
        },
    },
    created() {
        this.getData()
    },
    methods: {
        changeImportFile() {
            // eslint-disable-next-line prefer-destructuring
            this.importPayload.file = this.$refs.fileImport.files[0]
        },
        importFile() {
            this.isLoadingImport = true

            const form = new FormData()
            // eslint-disable-next-line no-restricted-syntax
            for (const key in this.importPayload) {
                if (this.importPayload.hasOwnProperty(key) && this.importPayload[key] != null) {
                    form.append(key, this.importPayload[key])
                }
            }
            this.$http.post('/item/import/merchant', form, {
                headers: { 'content-type': 'multipart/form-data' },
            })
                .then(response => {
                    successNotification(this, 'Success', 'Data successfully imported')
                    this.isLoadingImport = false
                    this.importPayload.file = null
                    this.getData()
                })
                .catch(error => {
                    if (error.response.data.meta.messages.length > 0) {
                        errorNotification(this, 'Oops!', error.response.data.meta.messages)
                    }
                    this.isLoadingImport = false
                })
        },
        getPayload(value) {
            this.formPayload = value
        },
        getFilter(value) {
            this.filter = value
        },
        cleanUpForm() {
            this.editId = null
            this.iconBank = null
            this.formPayload = {
                name: '',
                description: '',
                logo: '',
                item_uuid: null
            }
            this.validations = ''
        },
        editItem(item) {
            this.cleanUpForm()
            this.editId = item.uuid
            this.iconBank = item.logo_url
            let parent = null

            if (item.parent) {
                parent = {
                    name: item.parent.name,
                    uuid: item.parent.uuid
                }
            }
            this.$store.commit('item/setFormPayload', this.formPayload = {
                name: item.name,
                description: item.description,
                parent_uuid: parent
            })
            this.$bvModal.show('modal-item')
        },
        async getData(page = 1) {
            this.isLoading = true
            const queryParams = this.filter
            queryParams.page = page
            queryParams.category_type = 'category_1'
            this.$store.dispatch('item/getData', { params: queryParams }).then(result => {
                this.isLoading = false
                this.result = result.data
                this.currentPage = result.data.current_page
            }).catch(err => {
                // eslint-disable-next-line no-console
                console.log(err)
            })
        },
        createItem() {
            const form = this.preparePayload()
            this.isLoading = true
            this.$store.dispatch('item/postData', {
                query: '',
                payload: form,
            })
                .then(() => {
                    this.getData()
                    successNotification(this, 'Success', 'Kategori berhasil di buat')
                    this.cleanUpForm()
                    this.$bvModal.hide('modal-item')
                    this.isLoading = false
                })
                .catch(error => {
                    if (error.response.data.meta.validations) {
                        this.validations = error.response.data.meta.validations
                    }
                    this.isLoading = false
                })
        },
        updateItem() {
            const form = this.preparePayload()
            this.isLoading = true
            form.append('_method', 'PATCH')

            this.$store.dispatch('item/postData', {
                query: `/${this.editId}`,
                payload: form,
            })
                .then(() => {
                    this.$bvModal.hide('form-bank-modal')
                    this.getData()
                    successNotification(this, 'Success', 'Kategori berhasil di ubah!')
                    this.cleanUpForm()
                    this.isLoading = false
                    this.$bvModal.hide('modal-item')
                })
                .catch(error => {
                    if (error.response.data.meta.validations) {
                        this.validations = error.response.data.meta.validations
                        errorNotification(this, 'Oops!', error.response.data.meta.messages)
                    }
                    this.isLoading = false
                })
        },
        async deleteData(uuid) {
            this.$swal({
                title: 'Are you sure?',
                text: 'Are you sure to delete this item?',
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('item/deleteData', `/${uuid}`)
                        .then(() => {
                            this.getData()
                            this.$swal({
                                icon: 'success',
                                title: 'Success!',
                                text: 'Kategori berhasil dihapus',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                            })
                        })
                        .catch(error => {
                            if (error.response.data.meta.messages.length > 0) {
                                errorNotification(this, 'Oops!', error.response.data.meta.messages)
                            }
                        })
                }
            })
        },
        preparePayload() {
            const form = new FormData()
            // eslint-disable-next-line no-restricted-syntax
            for (const key in this.formPayload) {
                // eslint-disable-next-line no-prototype-builtins
                if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
                    if (key !== 'parent_uuid') {
                        form.append(key, this.formPayload[key])
                    }
                    if (key === 'parent_uuid') {
                        const uuid = typeof this.formPayload[key] === 'object' ? this.formPayload[key].uuid : this.formPayload[key]
                        form.append(key, uuid)
                    }
                }
            }
            return form
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.px-33 {
    padding-left: 33px;
    padding-right: 33px;
}

.px-16 {
    padding-left: 16px;
    padding-right: 16px;
}

.py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.py-18 {
    padding-top: 18px;
    padding-bottom: 18px;
}


.master-toko {
    height: 100vh
}
</style>
